import {inject, Injectable} from '@angular/core';
import {Observable, of, switchMap} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AuthGqlDataService} from './auth-gql-data.service';
import {AuthGqlAdapterService} from './auth-gql-adapter.service';
import {IAuthService} from '../../interfaces/auth-service.interface';
import {AuthService} from '../auth.service';
import {IApiResponse} from '../../interfaces/api-response';
import {IUser} from '../../interfaces/user';
import {AUTH_ON_PIC_TOGETHER_WITH_GQL} from './auth-on-pic.token';
import {ICustomerPreferences} from '../../interfaces/customer-preferences-gql.interface';
import {ApolloError} from '@apollo/client';
import {IS3Credentials} from '../../interfaces/s3-credantials.interface';

// TODO: remove picAuthService usage when PIC will be removed
@Injectable({providedIn: 'root'})
export class AuthGqlFacade implements IAuthService {
  private readonly authGqlDataService = inject(AuthGqlDataService);
  private readonly authGqlAdapter = inject(AuthGqlAdapterService);
  private readonly authOnPic = inject(AUTH_ON_PIC_TOGETHER_WITH_GQL, {optional: true}) ?? false;

  protected readonly picAuthService = new AuthService();

  private getCustomerPreferences(): Observable<ICustomerPreferencesResult> {
    return this.authGqlDataService.getCustomerPreferences().pipe(
      map(customerPreferences => ({
        customerPreferences,
        isFirstLogin: false,
      })),
      catchError((error: ApolloError) => this.handleCustomerPreferencesError(error))
    );
  }

  private handleCustomerPreferencesError(error: ApolloError): Observable<ICustomerPreferencesResult> {
    if (this.isNotFoundError(error)) {
      return this.createCustomerPreferences();
    }
    throw error; // Re-throw non-404 errors
  }

  private isNotFoundError(error: ApolloError): boolean {
    return error.graphQLErrors.some(err => err.extensions?.['code'] === '404');
  }

  private createCustomerPreferences(): Observable<ICustomerPreferencesResult> {
    return this.authGqlDataService.createCustomerPreferences().pipe(
      map(customerPreferences => ({
        customerPreferences,
        isFirstLogin: true,
      }))
    );
  }

  private getNoTracking(): boolean {
    return document.cookie.includes('no_tracking=true');
  }

  logInCheck(): Observable<IApiResponse<IUser>> {
    return this.getCustomerPreferences().pipe(
      switchMap(({customerPreferences, isFirstLogin}) => {
        return this.authGqlDataService.getUserData().pipe(
          map(user => {
            return {
              data: this.authGqlAdapter.userDataGqlToUser(
                customerPreferences,
                user,
                isFirstLogin,
                this.getNoTracking()
              ),
              success: true,
              message: 'success',
            };
          })
        );
      })
    );
  }

  // Used for authorize user on PIC. So if some page has no graphql api, it will be used
  jwtAuthorize(payload: Record<string, unknown>, scope?: string): Observable<IApiResponse<{user: Pick<IUser, 'id'>}>> {
    return this.authOnPic
      ? this.picAuthService.jwtAuthorize(payload, scope)
      : of({data: {user: {id: 1}}, success: true, message: 'success'}); // Result is not used in PSS, so we can mock it
  }

  // Just to interface implementation. Used by getting s3 policy by PIC. Also in unused getS3Signature$ in PSF. Should be removed
  signS3(): Observable<IApiResponse<IS3Credentials>> {
    throw new Error('Method not implemented.');
  }

  // Just to interface implementation. Used for logout on PIC
  logOut(): Observable<void> {
    return this.authOnPic ? this.picAuthService.logOut() : of(undefined);
  }
  //Just to interface implementation. Used for clear user cache on PIC
  dropAuthCache(): void {
    return this.authOnPic ? this.picAuthService.dropAuthCache() : undefined;
  }
}

interface ICustomerPreferencesResult {
  customerPreferences: ICustomerPreferences;
  isFirstLogin: boolean;
}
